
import { Component, Vue, Prop } from 'vue-property-decorator';
import Tabs from '@hokify/shared-components/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components/lib/components/Tab.vue';
import { truncateFct } from '@hokify/shared-components/lib/helpers/truncate';

export interface ISeoFooterData {
	title: string;
	id: string;
	searches: { title: string; description?: string; to?: string }[];
}

@Component({
	name: 'SeoFooter',
	components: {
		Tabs,
		Tab
	},
	methods: { truncateFct }
})
export default class SeoFooter extends Vue {
	@Prop({ type: Array, default: () => [] }) readonly data!: ISeoFooterData[];

	@Prop({ type: Boolean, default: true }) readonly showTabView!: boolean;

	@Prop({ type: Boolean, default: true }) readonly showHeadline!: boolean;

	@Prop({ type: String, default: 'center' }) readonly alignment!: 'center' | 'left';

	get availableCategories() {
		return this.data.filter(category => category.id !== 'job-searches' && category.searches.length);
	}

	get lastSearchesCategory() {
		return this.data.find(category => category.id === 'job-searches');
	}

	get alignmentClasses(): string[] {
		const classes: string[] = [];
		if (this.alignment === 'center') {
			classes.push('mx-auto');
			if (!this.$isMobile.phone) {
				classes.push('px-28');
			}
		}
		if (this.$isMobile.phone) {
			classes.push('px-5 pt-10');
		} else {
			classes.push('pt-12');
		}
		return classes;
	}
}
