var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.showTabView)?_c('Tabs',{ref:"tabs",staticClass:"max-w-6xl mx-auto",attrs:{"name":"seoTabs","align-center":true,"tablist-class":"seo-tabs-component-tabs mb-4"}},[_vm._l((_vm.availableCategories),function(category){return [(category.id !== 'job-searches')?_c('Tab',{key:category.id,staticClass:"max-w-6xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4",attrs:{"id":category.id,"name":category.title}},_vm._l((category.searches),function(item){return _c('HokLink',{key:item.to,staticClass:"text-color-text hover:text-color-main",attrs:{"to":item.to,"link-type":"internal"}},[_c('div',{staticClass:"block w-full"},[_c('div',{staticClass:"truncate"},[_c('strong',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.truncateFct(item.title, 29))+"\n\t\t\t\t\t\t")])]),_vm._v(" "),(item.description)?_c('div',{staticClass:"truncate"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.truncateFct(`${item.description} Ergebnisse`, 29))+"\n\t\t\t\t\t")]):_vm._e()])])}),1):_vm._e()]}),_vm._v(" "),(
			_vm.lastSearchesCategory &&
			_vm.lastSearchesCategory.searches &&
			_vm.lastSearchesCategory.searches.length
		)?[_c('client-only',[_c('Tab',{key:_vm.lastSearchesCategory.id,staticClass:"max-w-6xl mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4",attrs:{"id":_vm.lastSearchesCategory.id,"name":_vm.lastSearchesCategory.title}},_vm._l((_vm.lastSearchesCategory.searches),function(item){return _c('HokLink',{key:item.to,staticClass:"text-color-text hover:text-color-main",attrs:{"to":item.to,"link-type":"internal"}},[_c('div',{staticClass:"block w-full"},[_c('div',{staticClass:"truncate"},[_c('b',[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.truncateFct(item.title, 29))+"\n\t\t\t\t\t\t\t")])]),_vm._v(" "),(item.description)?_c('div',{staticClass:"truncate"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.truncateFct(`${item.description} Ergebnisse`, 29))+"\n\t\t\t\t\t\t")]):_vm._e()])])}),1)],1)]:_vm._e()],2):_c('div',{staticClass:"bg-color-white max-w-[1400px] pb-12 pt-12",class:_vm.alignmentClasses},[(_vm.showHeadline)?_c('h3',{staticClass:"text-2xl text-color-text font-normal mb-8"},[_vm._v("\n\t\tNicht das gefunden was du suchst?"),(!_vm.$isMobile.phone)?_c('br'):_vm._e(),_vm._v("\n\t\tVerfeinere deine Suche:\n\t")]):_vm._e(),_vm._v(" "),_c('div',{class:[
			{ 'flex justify-between': !_vm.$isMobile.phone && _vm.alignment === 'center' },
			{ 'flex space-x-36': !_vm.$isMobile.phone && _vm.alignment === 'left' }
		]},[_vm._l((_vm.availableCategories),function(category){return _c('div',{key:category.id},[(category.searches.length)?_c('div',{class:_vm.$isMobile.phone ? 'mb-11' : ''},[_c('h4',[_vm._v(_vm._s(category.title))]),_vm._v(" "),_c('ul',{staticClass:"list-disc mb-5 px-3 ml-3"},_vm._l((category.searches.slice(0, 8)),function(item){return _c('li',{key:item.title,staticClass:"my-2 text-color-main"},[(item.title && item.to)?_c('HokLink',{staticClass:"hover:underline hover:text-color-main-hover",attrs:{"to":item.to,"link-type":"internal"}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(`${item.title}${item.description ? ` (${item.description})` : ''}`)+"\n\t\t\t\t\t\t")]):_vm._e()],1)}),0)]):_vm._e()])}),_vm._v(" "),(
				_vm.lastSearchesCategory &&
				_vm.lastSearchesCategory.searches &&
				_vm.lastSearchesCategory.searches.length
			)?[_c('client-only',[_c('div',{class:_vm.$isMobile.phone ? 'mb-11' : ''},[_c('h4',[_vm._v(_vm._s(_vm.lastSearchesCategory.title))]),_vm._v(" "),_c('ul',{staticClass:"list-disc mb-5 px-3 ml-3"},_vm._l((_vm.lastSearchesCategory.searches.slice(0, 8)),function(item){return _c('li',{key:item.title,staticClass:"my-2 text-color-main"},[(item.title && item.to)?_c('HokLink',{staticClass:"hover:underline hover:text-color-main-hover",attrs:{"to":item.to,"link-type":"internal"}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(`${item.title}${item.description ? ` (${item.description})` : ''}`)+"\n\t\t\t\t\t\t\t")]):_vm._e()],1)}),0)])])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }