// eslint-disable-next-line import/no-extraneous-dependencies
import type { MetaInfo } from 'vue-meta';
import type { IAPIPageSettings } from '@hokify/common';

const mapping = {
	at: 'de-AT',
	de: 'de-DE',
	ch: 'de-CH'
};

const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

type ImageUrl = string | undefined;

function metaByKey(
	data: { hid: string; content?: string; href?: string }[],
	key: string
): { hid: string; content?: string; href?: string } | undefined {
	return data.find(d => d.hid === key);
}

export const getAlternateUrls = (path: string, domains: string[]) => {
	const alternateUrls: {
		hid: string;
		rel: string;
		href: string;
		hreflang: string;
	}[] = [];

	const pathUrl = new URL(path);
	const splittedHost: string[] = pathUrl?.host?.split('.') || [];

	domains.forEach(d => {
		splittedHost[splittedHost.length - 1] = d;
		pathUrl.host = splittedHost.join('.');
		alternateUrls.push({
			hid: `alternate-${mapping[d]}`,
			rel: 'alternate',
			href: pathUrl.href.endsWith('/')
				? pathUrl.href.substring(0, pathUrl.href.length - 1)
				: pathUrl.href,
			hreflang: mapping[d]
		});
	});

	return alternateUrls;
};

export const getAllAlternateUrls = (path: string) => {
	const domains: string[] = [];
	Object.keys(mapping).forEach(key => {
		domains.push(key);
	});

	return getAlternateUrls(path, domains);
};

export const getCanonicalUrl = (path: string) => ({
	hid: 'canonical',
	rel: 'canonical',
	href: path
});

const getAlternatesFromPageSettings = (alternates: { url: string; lang: string }[]) =>
	alternates.map(alternate => ({
		hid: `alternate-${alternate.lang}`,
		rel: 'alternate',
		href: alternate.url,
		hreflang: alternate.lang
	}));

export const getHeadFromPageSettings = (
	pageSettings: IAPIPageSettings
): MetaInfo & { meta: Required<MetaInfo>['meta']; link: Required<MetaInfo>['link'] } => {
	const head: MetaInfo & { meta: Required<MetaInfo>['meta']; link: Required<MetaInfo>['link'] } = {
		link: [],
		meta: []
	};

	if (pageSettings.canonicalUrl) {
		head.link.push(getCanonicalUrl(pageSettings.canonicalUrl));
	}
	if (pageSettings.alternates) {
		head.link.push(...getAlternatesFromPageSettings(pageSettings.alternates));
	}

	// only use robots index if we have a canonicalUrl
	if (pageSettings.canonicalUrl && !pageSettings.noIndex) {
		head.meta.push({ hid: 'robots', name: 'robots', content: 'index, follow' });
	}

	return head;
};

export const removeByHid = (head: MetaInfo, hid: string): void => {
	if (head.meta) {
		head.meta = head.meta.filter(m => m.hid !== hid);
	}
};

export const getSocialMediaMetaData = (
	head: any,
	domain: string,
	imageUrls?: ImageUrl[],
	deepLink?: string,
	query? /* beware: this only works if page is NOT cached (= no index!) */,
	canonicalUrl?: string /* beware: this only works if page is NOT cached (= no index!) */,
	socialsTitle?: string /* Set this to overwrite og:title and twitter:title */,
	socialsDescription?: string /* Set this to overwrite og:description and twitter:description */,
	socialsImage?: string /* Set this to overwrite og:image and twitter:image */
): Required<MetaInfo>['meta'] => {
	const metaData: MetaInfo['meta'] = [];

	if (socialsTitle || head?.title) {
		metaData.push({
			hid: 'og:title',
			property: 'og:title',
			content: socialsTitle || head.title
		});
		metaData.push({
			hid: 'twitter:title',
			name: 'twitter:title',
			content: socialsTitle || head.title
		});
	}

	const meta = metaByKey(head.meta, 'description');
	if (meta?.content) {
		metaData.push({
			hid: 'og:description',
			property: 'og:description',
			content: socialsDescription || meta.content
		});
		metaData.push({
			hid: 'twitter:description',
			name: 'twitter:description',
			content: socialsDescription || meta.content
		});
	}

	if (socialsImage) {
		metaData.push({
			hid: 'og:image',
			property: 'og:image',
			content: socialsImage
		});
		metaData.push({
			hid: 'twitter:image',
			name: 'twitter:image',
			content: socialsImage
		});
	} else if (imageUrls && imageUrls.length > 0) {
		imageUrls.forEach(imageUrl => {
			if (imageUrl) {
				metaData.push({
					hid: 'og:image',
					property: 'og:image',
					content: imageUrl
				});
				metaData.push({
					hid: 'twitter:image',
					name: 'twitter:image',
					content: imageUrl
				});
			}
		});
	} else {
		try {
			metaData.push({
				hid: 'og:image',
				property: 'og:image',
				// eslint-disable-next-line global-require,import/no-dynamic-require
				content: require(`~/assets/img/linkpic_hokify.png`)
			});
		} catch (err: any) {
			console.warn(`cannot load linkpic_hokify for domain: ${domain}`, err);
		}
		try {
			metaData.push({
				hid: 'twitter:image',
				name: 'twitter:image',
				// eslint-disable-next-line global-require,import/no-dynamic-require
				content: require(`~/assets/img/linkpic_hokify.png`)
			});
		} catch (err: any) {
			console.warn(`cannot load linkpic_hokify for domain: ${domain}`, err);
		}
	}

	if (domain === 'de') {
		metaData.push({
			hid: 'fb:pages',
			name: 'fb:pages',
			property: 'fb:pages',
			content: '174150969653555'
		});
	}

	const canonical: string | undefined = canonicalUrl || metaByKey(head.link, 'canonical')?.href;
	let ogUrl = canonical;
	if (ogUrl && query) {
		const utmParams: { [key: string]: string } = {};
		utmKeys.forEach(key => {
			if (typeof query[key] === 'string') {
				utmParams[key] = query[key];
			}
		});
		if (Object.keys(utmParams).length > 0) {
			ogUrl += `?${new URLSearchParams(utmParams).toString()}`;
		}
	}

	if (ogUrl) {
		metaData.push({
			hid: 'og:url',
			property: 'og:url',
			content: ogUrl
		});
	}

	if (deepLink) {
		metaData.push({
			hid: 'al:android:package',
			property: 'al:android:package',
			content: 'com.yfabrik.hocknfinder'
		});
		metaData.push({
			hid: 'al:android:app_name',
			property: 'al:android:app_name',
			content: 'hokify'
		});
		metaData.push({
			hid: 'al:android:url',
			property: 'al:android:url',
			content: deepLink
		});
		metaData.push({ hid: 'al:ios:app_name', property: 'al:ios:app_name', content: 'hokify' });
		metaData.push({
			hid: 'al:ios:app_store_id',
			property: 'al:ios:app_store_id',
			content: '1069568033'
		});
		metaData.push({ hid: 'al:ios:url', property: 'al:ios:url', content: deepLink });
		metaData.push({
			hid: 'al:web:should_fallback',
			property: 'al:web:should_fallback',
			content: 'true'
		});
		if (canonical) {
			metaData.push({ hid: 'al:web:url', property: 'al:web:url', content: canonical });
		}
	}

	return metaData;
};
